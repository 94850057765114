$(document).ready(function() {
    wl_shared.init({
        projectId: 1,
        API_URL_STAT: '/api/v11/statistics/track/',
        apiUrl: '//api.pdgamedev.com',
        errorDestination: $('.error-text')
    });

    $('#email-form').on('submit', function(e) {
        e.preventDefault();
        wl_shared.registerUser($('.email-input').val());
    });

    $('.email-input').focus(function () {
        $('.error-text').text('');
    });

    $(document).on({
        'registration.done': function (e, param) {
            redirect(param.data);
        },
        'registration.fail': function (e, data) {
            try {
                var errorCode = data.data.responseJSON.error.code,
                    validationCode = 3;
                errorCode == validationCode ? wl_shared.addError(data.data.responseJSON.error.description.email) : wl_shared.addError(data.data.responseJSON.error.message);
            } catch (e) {
            }
        }
    });

    function redirect(token) {
        if (token) {
            window.location.href = "/sapi/loginByToken?token=" + token + "&landing=true";
        }
    }

    function gameMobUrl() {
        if ( $(window).width() < 1025 ) {
            $('iframe').attr('src', '/game_promo/mobile/other/roulette_mob');
        }
    }
    gameMobUrl();
});
